import { ScreenTypes } from 'src/components/VacancyPercentIndicator';
import { useSelector } from 'src/hooks/useSelector';
import { VacancySearchItem } from 'src/types/search/vacancy/card';
import { UserLabel } from 'src/utils/constants/userLabels';

interface VacancyLabels {
    isFewResponsesLabelShown: boolean;
    isNoResumeLabelShown: boolean;
    isAnyLabelShown: boolean;
    isKeySkillPercentLabelShown?: boolean;
    matchedKeySkillAnalyticsData?: {
        matchedKeySkillPercent: number;
        vacancyId: number;
        resumeId: number;
        screenType: ScreenTypes;
    };
}

const THRESHOLD = 10;

export const useVacancyLabels = ({ vacancy }: { vacancy: VacancySearchItem }): VacancyLabels => {
    const { vacancyId, totalResponsesCount, type, acceptIncompleteResumes, immediateRedirectUrl } = vacancy;

    const userLabels = useSelector((state) => state.userLabelsForVacancies[vacancyId]);
    const isResponded = userLabels?.includes(UserLabel.Response);
    const isFewResponsesLabelShown = totalResponsesCount < THRESHOLD && type !== 'advertising' && !isResponded;
    const isNoResumeLabelShown = Boolean(acceptIncompleteResumes || immediateRedirectUrl);

    const {
        usedResumeId: resumeId = 0,
        matchingResultMap,
        isSuitableSearch,
    } = useSelector((state) => state.searchCounts || {});

    const percent = matchingResultMap?.[vacancyId]?.match_percentage || 0;
    const matchedKeySkillAnalyticsData = {
        matchedKeySkillPercent: percent,
        vacancyId,
        resumeId,
        screenType: isSuitableSearch ? ScreenTypes.Suitable : ScreenTypes.Simple,
    };

    const isKeySkillPercentLabelShown = Boolean(matchedKeySkillAnalyticsData.matchedKeySkillPercent);
    const isAnyLabelShown = isFewResponsesLabelShown || isNoResumeLabelShown || isKeySkillPercentLabelShown;

    return {
        isFewResponsesLabelShown,
        isNoResumeLabelShown,
        isAnyLabelShown,
        isKeySkillPercentLabelShown,
        matchedKeySkillAnalyticsData,
    };
};
