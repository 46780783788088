import { PropsWithChildren, ReactNode } from 'react';
import classnames from 'classnames';

import { useElementShown } from '@hh.ru/analytics-js';
import brandySnippetCompanyButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/brandy_snippet/brandy_snippet_company_button_click';
import brandySnippetElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/brandy_snippet/brandy_snippet_element_shown';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useBrandedSnippetAnalytics } from 'src/components/BrandedSnippet/hooks/useBrandedSnippetAnalytics';
import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import { BrandSnippetDecoration } from 'src/models/brandSnippetEditor/brandSnippet.types';

import styles from './styles.less';

const TrlKeys = {
    vacancies: 'navi.item.vacancy',
};

const BrandSnippetView: TranslatedComponent<
    PropsWithChildren<{
        companyUrl?: string;
        vacancyId?: number;
        companyName?: string;
        isZpCrossposting?: boolean;
        children: ReactNode;
        brandingData: BrandSnippetDecoration;
    }>
> = ({ children, trls, companyUrl, vacancyId, companyName, isZpCrossposting, brandingData }) => {
    const analyticsData = useBrandedSnippetAnalytics(vacancyId);
    const ref = useElementShown(brandySnippetElementShown, analyticsData);

    const isMagritte = useMagritte();

    const { isLoading, hasAdditionalPicture, logo, picture, showViewForXs, backgroundStyle } = brandingData;

    const imageContent = (
        <div
            className={classnames(
                styles.brandedSnippetDecoration,
                showViewForXs ? styles.brandedSnippetDecorationXs : styles.brandedSnippetDecorationDesk
            )}
        >
            {logo?.src ? (
                <div
                    className={classnames(styles.brandedSnippetLogoWrapper, {
                        [styles.brandedSnippetLogoWrapperAlone]: !hasAdditionalPicture,
                        [styles.brandedSnippetLogoWrapperNotAloneXs]: hasAdditionalPicture && showViewForXs,
                        [styles.brandedSnippetLogoWrapperNotAloneDesk]: hasAdditionalPicture && !showViewForXs,
                        [styles.brandedSnippetPictureHidden]: isLoading,
                    })}
                >
                    <img alt="branded logo" className={styles.brandedSnippetLogo} {...logo} />
                </div>
            ) : null}
        </div>
    );

    return (
        <div
            ref={ref}
            className={classnames(styles.brandedSnippet, {
                [styles.brandedSnippetXs]: showViewForXs,
                [styles.brandedSnippetDesk]: !showViewForXs,
                [styles.brandedSnippetPictureSkeleton]: isLoading,
                [styles.brandedSnippetMagritte]: isMagritte,
            })}
            style={backgroundStyle}
            data-qa={`branded-snippet-vacancy-${vacancyId}`}
        >
            {hasAdditionalPicture ? (
                <img
                    alt="branded picture"
                    className={classnames(
                        styles.brandedSnippetPicture,
                        showViewForXs ? styles.brandedSnippetPictureXs : styles.brandedSnippetPictureDesk,
                        {
                            [styles.brandedSnippetPictureHidden]: isLoading,
                        }
                    )}
                    {...picture}
                />
            ) : null}
            <div className={styles.brandedSnippetContent}>{children}</div>
            {isZpCrossposting ? (
                <div
                    data-qa="vacancy-serp__vacancy-employer-logo"
                    aria-label={`${trls[TrlKeys.vacancies]} ${companyName}`}
                >
                    {imageContent}
                </div>
            ) : (
                <SPALink
                    to={companyUrl || '#'}
                    data-qa="vacancy-serp__vacancy-employer-logo"
                    aria-label={`${trls[TrlKeys.vacancies]} ${companyName}`}
                    target="_blank"
                    onClick={() => brandySnippetCompanyButtonClick(analyticsData)}
                >
                    {imageContent}
                </SPALink>
            )}
        </div>
    );
};

export default translation(BrandSnippetView);
