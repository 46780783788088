import { useCallback, useRef } from 'react';

import { Action, TooltipHover } from '@hh.ru/magritte-ui';
import { HeartOutlinedSize24, HeartFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { getDataQa } from 'Modules/VacancyToFavorite';
import Source from 'src/components/VacancySearchItem/types/Source';
import translation from 'src/components/translation';
import { useFavorite } from 'src/hooks/useFavorite';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import { sendAdvSearchVacancyAnalytics, AdvAnalyticsTypes } from 'src/utils/sendAdvAnalytics';

const TrlKeys = {
    addToFavorite: 'button.addToFavorites',
    removeFromFavorite: 'hint.starMirror',
};

interface Props {
    vacancyId: number;
    employerId: number;
    isClosedVacancy: boolean;
    clickUrl: string;
    isAdv: boolean;
    vacancySource: Source;
    isNarrowCard?: boolean;
}

const VacancyFavorite: TranslatedComponent<Props> = ({
    vacancyId,
    employerId,
    isClosedVacancy,
    clickUrl,
    isAdv,
    trls,
    vacancySource,
    isNarrowCard,
}) => {
    const favoriteIconRef = useRef(null);

    const onFavoriteButtonClick = useCallback(
        (favoriteState: boolean) => {
            if (favoriteState) {
                sendAdvSearchVacancyAnalytics({ clickUrl, isAdv, analyticsType: AdvAnalyticsTypes.FavoriteButton });
            }
        },
        [clickUrl, isAdv]
    );

    const { isFavoriteVacancy, toggleIsFavoriteVacancy, isAvailableChangeFavoriteState } = useFavorite({
        onFavoriteStateChanged: onFavoriteButtonClick,
        vacancyId,
        employerId,
        isClosedVacancy,
        isSendUxFeedback: true,
    });
    const userType = useSelector((state) => state.userType);

    if (
        (!isAvailableChangeFavoriteState || userType !== UserType.Applicant) &&
        vacancySource !== Source.EmployerVacancy
    ) {
        return null;
    }

    return (
        <>
            <Action
                onClick={toggleIsFavoriteVacancy}
                aria-label={trls[isFavoriteVacancy ? TrlKeys.removeFromFavorite : TrlKeys.addToFavorite]}
                data-qa={getDataQa(isFavoriteVacancy, true, isNarrowCard)}
                mode={isFavoriteVacancy ? 'primary' : 'secondary'}
                style={isFavoriteVacancy ? 'negative' : 'neutral'}
                icon={isFavoriteVacancy ? HeartFilledSize24 : HeartOutlinedSize24}
                ref={favoriteIconRef}
            />
            {isFavoriteVacancy ? (
                <TooltipHover key={1} activatorRef={favoriteIconRef} placement="top-center">
                    {trls[TrlKeys.removeFromFavorite]}
                </TooltipHover>
            ) : (
                <TooltipHover key={2} activatorRef={favoriteIconRef} placement="top-center">
                    {trls[TrlKeys.addToFavorite]}
                </TooltipHover>
            )}
        </>
    );
};

export default translation(VacancyFavorite);
