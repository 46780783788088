import { useCallback, useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';

import elementSpy, { Bound, ElementSpyInstance } from 'bloko/common/elementSpy';

import { AppStore } from 'src/app/store';
import { resumeSearchSeenAnalyticsAction } from 'src/models/resumeSearchSeenAnalytics';
import { vacancySearchSeenAnalyticsAction } from 'src/models/vacancySearchSeenAnalytics';
import { SearchType } from 'src/types/search/common';
import { addVisibilityChangeListener, sendSeenAnalytics } from 'src/utils/seenAnalytics';

import { useSelector } from 'src/hooks/useSelector';

type SearchResultShownArguments =
    | {
          searchType: SearchType.Vacancy;
          searchRid?: string;
          itemId: number;
      }
    | {
          searchType: SearchType.Resume;
          searchRid?: string;
          itemId: string;
      };

const useSearchResultShown = ({
    searchType,
    searchRid,
    itemId,
}: SearchResultShownArguments): ((element: HTMLElement) => ElementSpyInstance) => {
    const dispatch = useDispatch();
    const store = useStore<AppStore>();
    const isFavoritesPage = useSelector(({ router }) =>
        router.location.pathname.startsWith('/applicant/favorite_vacancies')
    );

    useEffect(() => {
        return () => {
            sendSeenAnalytics(true);
        };
    }, []);

    const onElementShown = useCallback(
        (element: HTMLElement) => {
            let wasShown = false;
            const handleElementShown = () => {
                if (wasShown || !searchRid || isFavoritesPage) {
                    return;
                }
                wasShown = true;

                if (searchType === SearchType.Vacancy) {
                    dispatch(vacancySearchSeenAnalyticsAction({ searchRid, itemId }));
                } else if (searchType === SearchType.Resume) {
                    dispatch(resumeSearchSeenAnalyticsAction({ searchRid, itemId }));
                }
                addVisibilityChangeListener(store);
            };

            return elementSpy(element, {
                onShow: handleElementShown,
                trackingBounds: [Bound.Bottom],
            });
        },
        [searchRid, isFavoritesPage, searchType, store, dispatch, itemId]
    );

    return onElementShown;
};

export { useSearchResultShown };
