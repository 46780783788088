import { useCallback, useLayoutEffect, useRef } from 'react';

import { sendAnythingViewAnalytics, VacancyOfTheDayLocation, SpyParams } from 'src/utils/sendAdvSpyAnalytics';

type UseVacancyOfTheDayAnalytics = (
    vacancy: { isVacancyOfTheDay?: boolean; viewUrl: string },
    location: VacancyOfTheDayLocation,
    spyParams?: SpyParams
) => (element: HTMLElement | null) => void;

const useVacancyOfTheDayAnalytics: UseVacancyOfTheDayAnalytics = (vacancy, location, spyParams) => {
    const unsubscribe = useRef<(() => void) | null>(null);
    const { isVacancyOfTheDay, viewUrl } = vacancy;

    useLayoutEffect(
        () => () => {
            unsubscribe.current?.();
            unsubscribe.current = null;
        },
        []
    );

    return useCallback(
        (element: HTMLElement | null) => {
            if (!element || !isVacancyOfTheDay) {
                return;
            }

            unsubscribe.current?.();

            unsubscribe.current = sendAnythingViewAnalytics({
                element,
                viewUrl,
                location,
                spyParams,
            });
        },
        [location, spyParams, viewUrl, isVacancyOfTheDay]
    );
};

export { useVacancyOfTheDayAnalytics };
