import { VSpacing, HSpacing, Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyMetroList from 'src/components/VacancySearchItem/MetroList';
import { useCardContainerClassNames } from 'src/components/VacancySearchItem/hooks/useCardContainerClassNames';
import translation from 'src/components/translation';

import { VacancyAddressProps } from 'src/components/VacancySearchItem/Address/types';
import { getAddressName } from 'src/components/VacancySearchItem/Address/utils';

const VacancyAddress: TranslatedComponent<VacancyAddressProps> = ({ area, address, trls }) => {
    const metroList = address?.metroStations?.metro ?? [];
    const { wideCardContainerClassName, narrowCardContainerClassName } = useCardContainerClassNames();
    const isAddressWithMetro = metroList.length > 0;
    const addressName = getAddressName({ area, address, isAddressWithMetro, trls });

    const areaNameElement = addressName && (
        <Text typography="label-3-regular" Element="span" data-qa="vacancy-serp__vacancy-address">
            {addressName}
        </Text>
    );

    return (
        <>
            <div className={narrowCardContainerClassName}>
                <VSpacing default={12} />
                {areaNameElement}
                {isAddressWithMetro && (
                    <>
                        <VSpacing default={4} />
                        <VacancyMetroList metroList={metroList} />
                    </>
                )}
            </div>

            <div className={wideCardContainerClassName}>
                <VSpacing default={8} />
                {areaNameElement}
                {isAddressWithMetro && (
                    <>
                        <HSpacing default={12} />
                        <VacancyMetroList metroList={metroList} />
                    </>
                )}
            </div>
        </>
    );
};

export default translation(VacancyAddress);
