import { Tag } from '@hh.ru/magritte-ui';
import { CompensationFrequency } from '@hh.ru/types-hh-microcore';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useEnableCompensationWithModeAndFrequency } from 'src/hooks/useEnableCompensationWithModeAndFrequency';
import { VacancyCompensation } from 'src/models/vacancy/compensation.type';
import { ExperienceOption } from 'src/types/search/common/criteria';
import { ScheduleCamelCase } from 'src/utils/constants/schedule';

const TrlKeys = {
    experience: {
        title: 'vacancy.experience.title',
        [ExperienceOption.NoExperience]: 'vacancy.experience.noExperience',
        [ExperienceOption.Between1And3]: 'vacancy.experience.between1And3',
        [ExperienceOption.Between3And6]: 'vacancy.experience.between3And6',
        [ExperienceOption.MoreThan6]: 'vacancy.experience.moreThan6',
    },
    schedule: {
        [ScheduleCamelCase.Remote]: 'label.workSchedule.canWorkRemotely',
        [ScheduleCamelCase.FlyInFlyOut]: 'label.workSchedule.flyInFlyOut',
    },
    // TODO: https://jira.hh.ru/browse/HH-239449
    compensation: {
        title: 'vacancy.view.compensation.frequency.label',
        [CompensationFrequency.Daily]: 'compensationFrequency.DAILY',
        [CompensationFrequency.Weekly]: 'compensationFrequency.WEEKLY',
        [CompensationFrequency.TwicePerMonth]: 'compensationFrequency.TWICE_PER_MONTH',
        [CompensationFrequency.Monthly]: 'compensationFrequency.MONTHLY',
        [CompensationFrequency.PerProject]: 'compensationFrequency.PER_PROJECT',
    },
};

interface ExperienceAndScheduleProps {
    workExperience: ExperienceOption;
    workSchedule: ScheduleCamelCase;
    compensation: VacancyCompensation;
}

const ExperienceAndSchedule: TranslatedComponent<ExperienceAndScheduleProps> = ({
    workExperience,
    workSchedule,
    compensation,
    trls,
}) => {
    const enableNewCompensation = useEnableCompensationWithModeAndFrequency();

    const isScheduleAllowedLabelShown =
        workSchedule === ScheduleCamelCase.Remote || workSchedule === ScheduleCamelCase.FlyInFlyOut;

    const compensationFrequency = enableNewCompensation ? compensation.frequency : null;

    if (!workExperience && !isScheduleAllowedLabelShown && !compensationFrequency) {
        return null;
    }

    const experienceText =
        workExperience === ExperienceOption.NoExperience
            ? trls[TrlKeys.experience[workExperience]]
            : format(trls[TrlKeys.experience.title], { '{0}': trls[TrlKeys.experience[workExperience]] });

    return (
        <>
            <Tag>
                <span data-qa={`vacancy-serp__vacancy-work-experience-${workExperience}`}>{experienceText}</span>
            </Tag>
            {isScheduleAllowedLabelShown && (
                <Tag>
                    <span data-qa={`vacancy-label-work-schedule-${workSchedule}`}>
                        {trls[TrlKeys.schedule[workSchedule]]}
                    </span>
                </Tag>
            )}
            {!!compensationFrequency && (
                <Tag>
                    <span data-qa={`vacancy-serp__vacancy-compensation-frequency-${compensationFrequency}`}>
                        {`${trls[TrlKeys.compensation.title]}: `}
                        {trls[TrlKeys.compensation[compensationFrequency]].toLowerCase()}
                    </span>
                </Tag>
            )}
        </>
    );
};

export default translation(ExperienceAndSchedule);
