import { LangTrls } from 'bloko/common/hooks/useTranslations';

import { VacancyAddressProps } from 'src/components/VacancySearchItem/Address/types';

const MAX_ADDRESS_LENGTH = 100;

type GetAddressName = (
    args: VacancyAddressProps & {
        isAddressWithMetro: boolean;
        trls: LangTrls;
    }
) => string | undefined;

const TrlKeys = {
    district: 'address.district',
};

export const getAddressName: GetAddressName = ({ area, address, isAddressWithMetro, trls }) => {
    const publicationAddress = area?.name;
    const addressCity = address?.city;
    if (!addressCity) {
        return publicationAddress;
    }
    if (isAddressWithMetro) {
        return addressCity;
    }
    if (address.districtDto?.name) {
        const addressWithDistrict = `${addressCity}, ${trls[TrlKeys.district]} ${address.districtDto.name}`;
        return addressWithDistrict.length <= MAX_ADDRESS_LENGTH ? addressWithDistrict : addressCity;
    }
    if (address.street) {
        const addressWithStreet = [addressCity, address.street, address.building].filter(Boolean).join(', ');
        return addressWithStreet.length <= MAX_ADDRESS_LENGTH ? addressWithStreet : addressCity;
    }
    return addressCity;
};
