import { useMemo } from 'react';

import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import { isString } from 'Utils/Utils';
import getGradient from 'src/components/BrandedSnippet/utils/getGradient';
import getPictureAttributes from 'src/components/BrandedSnippet/utils/getPictureAttributes';
import { useSearchSettings } from 'src/hooks/search/useSearchSettings';
import { BrandSnippetDecoration, BrandSnippet, PictureType } from 'src/models/brandSnippetEditor/brandSnippet.types';

import { useImagesLoader } from 'src/components/BrandedSnippet/hooks/useImagesLoader';

const useBrandedSnippetDecoration = ({
    branding,
    isBrandedSnippetAllowed,
    isForceMobileView,
}: {
    branding?: BrandSnippet;
    isBrandedSnippetAllowed: boolean;
    isForceMobileView?: boolean;
}): BrandSnippetDecoration => {
    const isXS = useBreakpoint() === Breakpoint.XS;
    const { isVacancySearchMapPage } = useSearchSettings();
    const showViewForXs = isForceMobileView || isXS || isVacancySearchMapPage;
    const logo = branding ? getPictureAttributes(branding, showViewForXs, PictureType.Logo) : undefined;
    const picture = branding ? getPictureAttributes(branding, showViewForXs, PictureType.Picture) : undefined;
    const logoSrc = logo?.src;
    const pictureSrc = picture?.src;
    const pictureSources = useMemo(() => [logoSrc, pictureSrc].filter(isString), [logoSrc, pictureSrc]);
    const { isLoading, hasLoadingError } = useImagesLoader(pictureSources, isBrandedSnippetAllowed);
    const hasAdditionalPicture = !!picture?.src;
    const background = branding?.background;
    const backgroundStyle = isLoading
        ? {}
        : { backgroundImage: getGradient(background?.gradient), backgroundColor: background?.color };

    return {
        hasLoadingError,
        isLoading,
        hasAdditionalPicture,
        logo,
        picture,
        showViewForXs,
        backgroundStyle,
    };
};

export { useBrandedSnippetDecoration };
