import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import brandySnippetButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/brandy_snippet/brandy_snippet_button_click';
import vacancySuitableItemButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_suitable_item_button_click';
import employerVacancyItemButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/employer_page_vacancy_search/employer_vacancy_item_button_click';
import vacancySimilarItemForEmployerButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/vacancy/vacancy_similar_item_for_employer_button_click';
import { Link } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import { useBrandedSnippetContext } from 'src/components/BrandedSnippet/context/BrandedSnippetContext';
import { useBrandedSnippetAnalytics } from 'src/components/BrandedSnippet/hooks/useBrandedSnippetAnalytics';
import { RelatedVacanciesType } from 'src/components/RelatedVacancies/relatedVacanciesTypes';
import Source from 'src/components/VacancySearchItem/types/Source';
import { getVacancyLinkURL } from 'src/components/VacancySearchItem/utils/getVacancyLinkURL';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import { VacancySearchItem } from 'src/types/search/vacancy/card';
import { sendAnythingClickAnalytics, VacancyOfTheDayLocation } from 'src/utils/sendAdvSpyAnalytics';

import styles from './styles.less';

export interface LinkToVacancyProps {
    vacancy: VacancySearchItem;
    vacancySource: string;
    hhtmFromLabel?: string;
    criteriaText: string;
}

const LinkToVacancy: FC<LinkToVacancyProps & PropsWithChildren> = ({
    vacancy,
    vacancySource,
    hhtmFromLabel,
    criteriaText,
    children,
}) => {
    const { '@click': advClickUrl, clickUrl, '@isAdv': isAdv, links, vacancyId, isVacancyOfTheDay } = vacancy;
    const vacancyView = useSelector((state) => state.vacancyView);
    const userType = useSelector((state) => state.userType);

    const isBot = useSelector((state) => state.isBot);
    const isSearchCatalog = useSelector((state) => state.isSearchCatalog);

    const isSeoLink = isBot && isSearchCatalog;

    const url = getVacancyLinkURL({
        criteriaText,
        isAdv,
        clickUrl,
        advClickUrl,
        isVacancyOfTheDay,
        desktopLink: links.desktop,
    });

    const showBrandedSnippet = !!useBrandedSnippetContext();
    const BrandedSnippetAnalyticsData = useBrandedSnippetAnalytics(vacancyId);

    const sendClickAnalytics = () => {
        if (vacancy.isVacancyOfTheDay && vacancy.clickUrl) {
            void sendAnythingClickAnalytics({
                location: VacancyOfTheDayLocation.VacancySearchResult,
                clickUrl: vacancy.clickUrl,
            });
        }

        if (vacancySource === Source.RelatedVacancies) {
            vacancySuitableItemButtonClick({
                hhtmSourceLabel: 'vacancy_bottom_block',
                vacancyId: vacancyView.vacancyId!,
                vacancyTargetId: vacancyId,
            });
        }

        if (vacancySource === Source.EmployerRelatedVacancies) {
            vacancySimilarItemForEmployerButtonClick({
                recommendationType:
                    userType === UserType.Anonymous ? RelatedVacanciesType.Similar : RelatedVacanciesType.Suitable,
                vacancyId: vacancyView.vacancyId || 0,
                relatedVacancyId: vacancyId,
            });
        }

        if (vacancySource === Source.EmployerVacancySearch) {
            employerVacancyItemButtonClick({ vacancyId, employerId: vacancy?.company?.id });
        }

        showBrandedSnippet && brandySnippetButtonClick(BrandedSnippetAnalyticsData);
    };

    return (
        <span className={classnames(styles.customColorMagritteLink, styles.vacancyNameWrapper)}>
            <Link
                Element={SPALink}
                to={url}
                additionalQueryParams={{ hhtmFromLabel }}
                target="_blank"
                isSeoLink={isSeoLink}
                onClick={sendClickAnalytics}
                style="neutral"
                inline
                enableVisited
                data-qa="serp-item__title"
                typography="title-4-semibold"
            >
                {children}
            </Link>
        </span>
    );
};

export default LinkToVacancy;
