import { Text } from '@hh.ru/magritte-ui';
import { CompensationMode } from '@hh.ru/types-hh-microcore';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import CompensationFormattedText from 'src/components/Compensation';
import translation from 'src/components/translation';
import { useEnableCompensationWithModeAndFrequency } from 'src/hooks/useEnableCompensationWithModeAndFrequency';
import { VacancyCompensation as VacancyCompensationType } from 'src/models/vacancy/compensation.type';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

interface CompensationProps {
    compensation: VacancyCompensationType;
}

const TrlKeys = {
    net: 'employer.newVacancy.compensation.net',
    gross: 'employer.newVacancy.compensation.gross',
    // TODO: https://jira.hh.ru/browse/HH-239449
    mode: {
        [CompensationMode.Month]: 'compensationMode.MONTH',
        [CompensationMode.Shift]: 'compensationMode.SHIFT',
        [CompensationMode.Hour]: 'compensationMode.HOUR',
        [CompensationMode.FlyInFlyOut]: 'compensationMode.FLY_IN_FLY_OUT',
        [CompensationMode.Service]: 'compensationMode.SERVICE',
    },
};

const Compensation: TranslatedComponent<CompensationProps> = ({ compensation, trls }) => {
    const enableNewCompensation = useEnableCompensationWithModeAndFrequency();

    const { from, to, perModeFrom, perModeTo, gross, mode } = compensation;

    const fallbackFrom = perModeFrom ?? from ?? null;
    const fallbackTo = perModeTo ?? to ?? null;

    if (!fallbackFrom && !fallbackTo) {
        return null;
    }

    return (
        <Text Element="span" typography="label-1-regular">
            <CompensationFormattedText {...compensation} analyticsContext="VacancySearchItem" dash />
            {NON_BREAKING_SPACE}
            {enableNewCompensation && mode && `${trls[TrlKeys.mode[mode]].toLowerCase()}, `}
            {trls[gross ? TrlKeys.gross : TrlKeys.net]}
        </Text>
    );
};

export default translation(Compensation);
